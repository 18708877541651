






import Vue from 'vue';
import { v4 as uuidv4 } from 'uuid';
import { debounce } from 'lodash-es';
import { Map, MapBrowserEvent, View } from 'ol';
import OSM from 'ol/source/OSM';
import TileLayer from 'ol/layer/Tile';
import { fromLonLat } from 'ol/proj';

export default Vue.extend({
  props: {
    view: {
      type: Object as () => View,
    },
  },

  data(): {
    id: string;
    map?: Map;
  } {
    return {
      id: uuidv4(),
      map: undefined,
    };
  },

  provide(): {
    _getMap?: () => Map | undefined;
  } {
    return {
      _getMap: () => this.map,
    };
  },

  mounted() {
    this.map = new Map({
      target: this.id,
      layers: [new TileLayer({ source: new OSM() })],
      view:
        this.view ||
        new View({
          projection: 'EPSG:3857',
          center: fromLonLat([0, 0]),
          zoom: 4,
        }),
    });

    this.map.on('singleclick', (evt: MapBrowserEvent) => {
      this.$emit('singleclick', evt);
    });

    this.map.on(
      'pointermove',
      debounce((evt: MapBrowserEvent) => {
        this.$emit('pointermove', evt);
      }, 1)
    );
  },

  watch: {
    view: {
      handler(value) {
        this.map?.setView(value);
      },
    },
  },
});
